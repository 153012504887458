import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { useTranslation } from "react-i18next";

import { ImgGrid, Layout, SectionTitle, SEO } from "components";
import { StaticImage } from "gatsby-plugin-image";

type TDataArray = undefined | { date: string; text: string }[];

const informations = () => {
  const { t } = useTranslation();
  const [dateArr, setDateArr] = useState<TDataArray>([]);
  const [discipline, setDiscipline] = useState<string[]>([]);
  const [field, setField] = useState<string[]>([]);
  const [organizingCommittee, setOrganizingCommittee] = useState<string[]>([]);
  const [scientificCommittee, setScientificCommittee] = useState<string[]>([]);

  useEffect(() => {
    setDateArr(
      t("informations.dateArray", { defaultValue: [], returnObjects: true })
    );
    setDiscipline(
      t("informations.discipline", { defaultValue: [], returnObjects: true })
    );
    setField(
      t("informations.field", { defaultValue: [], returnObjects: true })
    );
    setOrganizingCommittee(
      t("informations.organizingCommittee", {
        defaultValue: [],
        returnObjects: true,
      })
    );
    setScientificCommittee(
      t("informations.scientificCommittee", {
        defaultValue: [],
        returnObjects: true,
      })
    );
  }, []);

  const parseListItem = (text: string) => {
    const reg = /\.\s(\S+\s\S*)(\s\(|,)/i;
    const findString = reg.exec(text);
    return findString
      ? text.replace(
          findString[1],
          `<span class="informations__text--bold">${findString[1]}</span>`
        )
      : "";
  };

  return (
    <Layout>
      <SEO
        title={t`config.nav.informations`}
        description={t`informations.paragraph_1`}
      />
      <article>
        <div className="informations container">
          <SectionTitle title={t`config.title.informations`} />
          <section className="content-text">
            <p>{t("informations.paragraph_1")}</p>
            <p>{t("informations.paragraph_2")}</p>
            <ul>
              {discipline.map((e) => (
                <li key={e}>{e}</li>
              ))}
            </ul>
            <p>{t("informations.paragraph_3")}</p>
            <ul>
              {field.map((e) => (
                <li key={e}>{e}</li>
              ))}
            </ul>
          </section>
          <section className="informations__date content-text">
            <h4 className="informations__title">
              {t("informations.importantDates")}
            </h4>
            {dateArr &&
              dateArr.map(({ date, text }) => (
                <p>
                  <span className="informations__text--bold">{date}</span>{" "}
                  {text}
                </p>
              ))}
          </section>
          <StaticImage
            alt="Framework program"
            objectFit="contain"
            placeholder="blurred"
            src="../../images/informations/framework-program.png"
          />
        </div>
        <section className="informations__people gray">
          <div className="informations__content container">
            <h4>{t("informations.paragraph_4")}</h4>
            <ol>
              {organizingCommittee.map((e) => (
                <li key={e} className="informations__list-item">
                  <div dangerouslySetInnerHTML={{ __html: parseListItem(e) }} />
                </li>
              ))}
            </ol>
            <br />
            <h4>{t("informations.paragraph_5")}</h4>
            <ol>
              {scientificCommittee.map((e) => (
                <li key={e} className="informations__list-item">
                  <div dangerouslySetInnerHTML={{ __html: parseListItem(e) }} />
                </li>
              ))}
            </ol>
          </div>
        </section>
        <section className="informations__partners container">
          <SectionTitle title={t`config.title.partners`} center />
          <ImgGrid />
        </section>
      </article>
    </Layout>
  );
};

export default informations;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
